var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Icon"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeIcon) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("By default ")]), _c('code', [_vm._v("<b-form-rating>")]), _c('span', [_vm._v(" uses the Bootstrap Icons icons ")]), _c('code', [_vm._v("'star', 'star-half', 'star-fill',")]), _c('span', [_vm._v(" and the icon ")]), _c('code', [_vm._v("'x'")]), _c('span', [_vm._v(" (for the optional clear button). You can specify alternate Bootstrap Icons to use via the ")]), _c('code', [_vm._v(" icon-empty, icon-half, icon-full")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("icon-clear")]), _c('span', [_vm._v(" props.")])]), _c('b-form-rating', {
    attrs: {
      "show-clear": "",
      "variant": "danger"
    }
  }, [_c('feather-icon', {
    attrs: {
      "slot": "icon-empty",
      "icon": "FrownIcon",
      "size": "18"
    },
    slot: "icon-empty"
  }), _c('feather-icon', {
    attrs: {
      "slot": "icon-half",
      "icon": "MehIcon",
      "size": "18"
    },
    slot: "icon-half"
  }), _c('feather-icon', {
    staticClass: "text-success",
    attrs: {
      "slot": "icon-full",
      "icon": "SmileIcon",
      "size": "18"
    },
    slot: "icon-full"
  }), _c('feather-icon', {
    attrs: {
      "slot": "icon-clear",
      "icon": "XCircleIcon",
      "size": "18"
    },
    slot: "icon-clear"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }