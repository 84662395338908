var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Input Group"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeInputGroup) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("The following is an example of placing ")]), _c('code', [_vm._v("<b-form-rating>")]), _c('span', [_vm._v(" in an input group:")])]), _c('div', [_c('b-input-group', [_c('b-input-group-prepend', [_c('b-button', {
    on: {
      "click": function click($event) {
        _vm.value = null;
      }
    }
  }, [_vm._v(" Clear ")])], 1), _c('b-form-rating', {
    attrs: {
      "inline": "",
      "color": "#ff8800"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('b-input-group-append', [_c('b-input-group-text', {
    staticClass: "justify-content-center px-1"
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }