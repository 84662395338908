var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Show value"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeShowValue) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To show the current rating value simply set the ")]), _c('code', [_vm._v("show-value")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(". You can use ")]), _c('code', [_vm._v("show-value-max")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("precision")]), _c('span', [_vm._v(" prop to get more conrol over display value")])]), _c('div', [_c('b-form-rating', {
    staticClass: "d-block",
    attrs: {
      "show-value": "",
      "inline": "",
      "no-border": "",
      "variant": "warning"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('br'), _c('b-form-rating', {
    attrs: {
      "readonly": "",
      "show-value": "",
      "show-value-max": "",
      "variant": "warning",
      "inline": "",
      "no-border": ""
    },
    model: {
      value: _vm.value2,
      callback: function callback($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  }), _c('br'), _c('b-form-rating', {
    attrs: {
      "readonly": "",
      "show-value": "",
      "variant": "warning",
      "inline": "",
      "no-border": "",
      "precision": "2"
    },
    model: {
      value: _vm.value2,
      callback: function callback($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }