var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Inline mode"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeInline) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("By default, ")]), _c('code', [_vm._v("<b-form-rating>")]), _c('span', [_vm._v(" occupies 100% width of the parent container. In some situations you may prefer the custom input to occupy on the space required for it's contents. Simply set the ")]), _c('code', [_vm._v("inline")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(" to render the component in inline mode:")])]), _c('label', {
    staticClass: "mr-1",
    attrs: {
      "for": "rating-inline"
    }
  }, [_vm._v("Inline rating:")]), _c('b-form-rating', {
    attrs: {
      "id": "rating-inline",
      "inline": "",
      "no-border": "",
      "variant": "warning",
      "value": "4"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }