var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Clear button"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeClearButton) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Optionally show a clear icon via the ")]), _c('code', [_vm._v("show-clear")]), _c('span', [_vm._v(" prop. The value will be set to ")]), _c('code', [_vm._v("null")]), _c('span', [_vm._v(" when the clear icon is clicked")])]), _c('b-form-rating', {
    attrs: {
      "show-clear": "",
      "show-value": "",
      "inline": "",
      "variant": "warning"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }