var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Borderless"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBorderLess) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("By default, ")]), _c('code', [_vm._v("<b-from-rating>")]), _c('span', [_vm._v(" has borders around rating. Simply set the ")]), _c('code', [_vm._v("no-border")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(" to remove border.")])]), _c('div', [_c('label', {
    staticClass: "mr-1",
    attrs: {
      "for": "rating-sm-no-border"
    }
  }, [_vm._v("Small rating with no border")]), _c('b-form-rating', {
    attrs: {
      "id": "rating-sm-no-border",
      "no-border": "",
      "variant": "warning",
      "inline": "",
      "size": "sm"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _c('div', {
    staticClass: "mt-1"
  }, [_c('label', {
    staticClass: "mr-1",
    attrs: {
      "for": "rating-md-no-border"
    }
  }, [_vm._v("Default rating (medium) with border")]), _c('b-form-rating', {
    attrs: {
      "id": "rating-md-no-border",
      "variant": "warning",
      "inline": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _c('div', {
    staticClass: "mt-1"
  }, [_c('label', {
    staticClass: "mr-1",
    attrs: {
      "for": "rating-lg-no-border"
    }
  }, [_vm._v("Large rating with no border")]), _c('b-form-rating', {
    attrs: {
      "id": "rating-lg-no-border",
      "no-border": "",
      "variant": "warning",
      "size": "lg",
      "inline": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }